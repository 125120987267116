.swiper {
  width: 100%;
  height: 100%;
  border: 0.5px solid rgba(205, 194, 194, 0.343);
}

.swiper_slide {
  text-align: center;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper_slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
