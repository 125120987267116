@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@font-face {
  font-family: 'PSL Kanda Pro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/PSL152pro.ttf');
}
@font-face {
  font-family: 'PSL Kanda Pro';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/PSL153pro.ttf');
}
@font-face {
  font-family: 'PSL Kanda Pro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/PSL154pro.ttf');
}
@font-face {
  font-family: 'PSL Kanda Pro';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/PSL155pro.ttf');
}
@font-face {
  font-family: 'PSL Kanda Extra Pro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/PSL156pro.ttf');
}
@font-face {
  font-family: 'PSL Kanda Extra Pro';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/PSL157pro.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'PSL Kanda Pro', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* font-family: 'PSL Kanda Pro'; */
  background-color: #f8f8f8;
}

strong {
  font-family: 'PSL Kanda Extra Pro';
  font-weight: 400;
}
.indent-text-1 {
  text-indent: 24px;
}

.indent-text-2 {
  text-indent: 32px;
}

.padding-left-2 {
  padding-left: 16px;
}

.padding-left-3 {
  padding-left: 24px;
}

.padding-left-4 {
  padding-left: 32px;
}

.padding-left-5 {
  padding-left: 40px;
}

.terms-table {
  border: 1px solid #000;
  border-collapse: collapse;
}

.terms-table th,
.terms-table td,
.terms-table tr {
  border: 1px solid #000;
  border-collapse: collapse;
  padding: 8px;
}

.font-size-small {
  font-size: 16px !important;
}

.font-weight-bold {
  font-family: 'PSL Kanda Extra Pro';
  font-style: 'normal';
  font-weight: 400;
  font-size: '28px';
  line-height: '28px';
}

.text-align-left {
  text-align: left;
}

/* Swipper React */
/* target the active bullet */
.swiper-pagination-bullet-active {
  background-color: #1f2937;
}

/* Ol right parentheses */
ol {
  counter-reset: list;
}
ol > li {
  list-style: none;
}
ol > li:before {
  content: counter(list, lower-number) ') ';
  counter-increment: list;
}

a,
button {
  cursor: pointer;
}

.emphasis {
  font-family: 'PSL Kanda Extra Pro';
  font-weight: 400;
  text-decoration: underline;
}

.asterisk {
  font-size: 2em;
  color: red;
}
