.logo__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 3rem;
}
.logo_item {
  width: 100px;
  height: auto;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.image_logo {
  width: 100px;
  height: auto;
}

@media (max-width: 556px) {
  .logo__container {
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .logo_item {
    width: 60px;
  }
  .image_logo {
    width: 60px;
    height: auto;
  }
}
@media (min-width: 557px) and (max-width: 821px) {
  .logo__container {
    justify-content: center;
    gap: 2rem;
  }
  .logo_item {
    width: 100px;
  }
  .image_logo {
    width: 100px;
  }
}
